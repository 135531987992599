/**
 * @file
 * Main app root component
 */
import React from "react";
import { connect } from "react-redux";
import Scene from "./Scene";
import Choices from "./Choices";
import Story from "./Story";
import { makeChoice } from "./state/actions";
import { Container, Icon, Button } from "semantic-ui-react";
import "./App.css";
const App = props =>
  props.ending ? (
    <Container text>
      <div style={{ textAlign: "center", marginBottom: "2em" }}>
        <p style={{ margin: "2rem 0px 1rem 0" }}>
          <img src="images/splashes/finish.png" style={{ maxWidth: "300px" }} />
        </p>
        <h4>Thank you for taking the time to view this presentation!</h4>
        <h3 style={{ textAlign: "center" }}>An interactive presentation by</h3>
        <h2>Ian Jacobs</h2>
        <p>
          <Icon name="mail" />
          <b>ian@eonsaway.games</b>
          <br />
          <Icon name="call" />
          <b>079 527 8387</b>
        </p>
        <Button onClick={() => window.location.reload(false)}>
          <Icon name="refresh" />
          Restart
        </Button>
      </div>
    </Container>
  ) : (
    <Container text>
      <div className="App">
        <Scene tags={props.tags} />
        <Story tags={props.tags} sceneText={props.sceneText} />
        <Choices choices={props.currentChoices} makeChoice={props.makeChoice} />
      </div>
    </Container>
  );

const stateToProps = state => ({
  tags: state.tags,
  currentChoices: state.currentChoices,
  sceneText: state.sceneText,
  ending: state.ending
});

const dispatchToProps = dispatch => ({
  makeChoice: idx => dispatch(makeChoice(idx))
});

export default connect(
  stateToProps,
  dispatchToProps
)(App);
