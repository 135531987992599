/**
 * @file
 * Dynamic background for setting the scene
 */

import React from "react";
import { Progress } from "semantic-ui-react";

const splashImages = {
  playbox: "images/splashes/playbox.png",
  finish: "images/splashes/finish.png"
};
const headerImages = {
  gamification: "images/headers/gamification.png",
  products: "images/headers/products.png",
  estimates: "images/headers/estimates.png",
  roadmap: "images/headers/roadmap.png",
  funding: "images/headers/funding.png"
};
const progressImages = {
  zero_five: "images/progress/zero_five.png",
  one_five: "images/progress/one_five.png",
  two_five: "images/progress/two_five.png",
  three_five: "images/progress/three_five.png",
  four_five: "images/progress/four_five.png",
  five_five: "images/progress/five_five.png"
};

const defaultImage = "";
export default ({ tags }) => (
  <section style={{ margin: "2rem 0px 1rem 0" }}>
    {/* SPLASH */}
    {tags && splashImages[tags.splash] ? (
      <img src={splashImages[tags.splash]} style={{ maxWidth: "200px" }} />
    ) : (
      ""
    )}
    {/* HEADER */}
    {tags && headerImages[tags.header] ? (
      <img src={headerImages[tags.header]} style={{ maxWidth: "200px" }} />
    ) : (
      ""
    )}

    {/* PROGRESS */}
    {tags && tags.progress ? (
      <Progress
        percent={tags.progress}
        size="medium"
        indicating
        style={{
          maxWidth: "300px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "0"
        }}
        progress
      />
    ) : (
      ""
    )}
    {/*SUB HEADER */}
    {tags && tags.subheader ? <h3>{tags.subheader}</h3> : ""}
  </section>
);
