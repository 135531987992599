/**
 * @file
 * Container for all the story text
 */

import React from "react";
// import { height } from "window-size";
const backgroundImages = {
  gamification: "images/backgrounds/gamification.png",
  gamifyingLearning: "images/backgrounds/gamifying_learning.png",
  traditionalVSAction: "images/backgrounds/traditional_v_action.png",
  marketDriven: "images/backgrounds/market_driven.png",
  packages: "images/backgrounds/packages.png",
  package: "images/backgrounds/package.png",
  codebase: "images/backgrounds/small_factory.png",
  distribution: "images/backgrounds/large_factory.png",
  subscriptions: "images/backgrounds/subscriptions.png",
  skillCourses: "images/backgrounds/course_driven.png",
  formalTraining: "images/backgrounds/formal_training.png",
  map_one: "images/backgrounds/map_one.png",
  map_two: "images/backgrounds/map_two.png",
  map_three: "images/backgrounds/map_three.png",
  map_four: "images/backgrounds/map_four.png",
  map_full: "images/backgrounds/map_full.png",
  EstimatesTable: "images/backgrounds/estimates_table.png",
  employees: "images/backgrounds/employees.png"
};
const defaultImage = "";
const Story = ({ sceneText, tags }) => (
  <section className="story-text">
    {/* BACKGROUND */}
    {tags && backgroundImages[tags.background] ? (
      <img
        src={backgroundImages[tags.background]}
        style={{ maxWidth: "300px", padding: "1em" }}
      />
    ) : (
      ""
    )}
    {sceneText.map((text, idx) => (
      <p key={idx}>
        {
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ paddingTop: "1em" }}
          />
        }
      </p>
    ))}
  </section>
);

export default Story;
