/**
 * @file
 * Container component for player choices
 */

import React from "react";
import { Button, Icon, Segment } from "semantic-ui-react";

const choiceMap = {
  gamification: "What is Gamification?",
  gamificationVisited: "Tell me about Gamification again.",
  products: "What Products do you plan to sell?",
  productsVisited: "Show me the Products again.",
  estimates: "Show me your Estimates!",
  estimatesVisited: "Let me see your Estimates again.",
  roadmap: "Do you have a Roadmap to reach your Estimates?",
  roadmapVisited: "I want to see the Roadmap again.",
  funding: "How much will this Cost?",
  fundingVisited: "I want another look at the Funding required.",
  partner: "What do you need from a Partnership?",
  partnerVisited: "Tell me about Partnership again.",
  back: "I want to know about something else...",
  finish: "Finish!",
  disabled: "???"
};

const generateButton = ({ text }) => {
  const colorStrings = [
    "orange",
    "yellow",
    "green",
    "teal",
    "blue",
    "violet",
    "purple",
    "pink"
  ];
  const randomColorID = Math.floor(Math.random() * colorStrings.length);
  const randomColor = colorStrings[randomColorID];

  switch (text) {
    case choiceMap.gamification:
    case choiceMap.gamificationVisited:
      return (
        <Button color={text === choiceMap.gamification ? randomColor : ""}>
          <Icon name="game" />
          {text}
        </Button>
      );
    case choiceMap.products:
    case choiceMap.productsVisited:
      return (
        <Button color={text === choiceMap.products ? randomColor : ""}>
          <Icon name="gift" />
          {text}
        </Button>
      );
    case choiceMap.estimates:
    case choiceMap.estimatesVisited:
      return (
        <Button color={text === choiceMap.estimates ? randomColor : ""}>
          <Icon name="chart line" />
          {text}
        </Button>
      );
    case choiceMap.roadmap:
    case choiceMap.roadmapVisited:
      return (
        <Button color={text === choiceMap.roadmap ? randomColor : ""}>
          <Icon name="map" />
          {text}
        </Button>
      );
    case choiceMap.funding:
    case choiceMap.fundingVisited:
      return (
        <Button color={text === choiceMap.funding ? randomColor : ""}>
          <Icon name="money bill alternate" />
          {text}
        </Button>
      );
    case choiceMap.partner:
    case choiceMap.partnerVisited:
      return (
        <Button color={text === choiceMap.partner ? randomColor : ""}>
          <Icon name="handshake" />
          {text}
        </Button>
      );
    case choiceMap.back:
      return (
        <Button>
          <Icon name="arrow alternate circle left outline" />
          {text}
        </Button>
      );
    case choiceMap.finish:
      return (
        <Button color="green">
          <Icon
            name="flag checkered"
            style={{ margin: "0 .42857143em 0 -.21428571em" }}
          />
          {text}
        </Button>
      );
    case choiceMap.disabled:
      return (
        <button className="ui disabled button" disabled="" tabIndex="-1">
          <Icon name="question circle outline" />
          {text}
        </button>
      );
    default:
      return (
        <Button color={randomColor}>
          <Icon
            style={{ margin: "0 .42857143em 0 -.21428571em" }}
            name="arrow alternate circle right outline"
          />
          {text}
        </Button>
      );
  }
};

const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

const handleClick = makeChoice => {
  scrollToTop();
  return makeChoice;
};
const Choices = ({ choices, makeChoice }) => (
  <Segment.Group
    raised
    className="choices"
    style={{ marginBottom: "2em", marginTop: "2em" }}
  >
    <Segment>
      <h3>Make a decision...</h3>
    </Segment>
    {choices.map(choice => (
      <Segment
        key={choice.index}
        onClick={() => handleClick(makeChoice(choice.index))}
      >
        {generateButton(choice)}
      </Segment>
    ))}
  </Segment.Group>
);

export default Choices;
